/**
 * Observes a component's [componentElement] slot for specific child nodes specified by [tagNameToFilter].
 * It detects for modifications and retrieves a list of them in the [callback].
 *
 * It automatically scans for nodes on creation.
 * You need to manually disconnect this observer by calling it's [disconnect] method.
 */
import { notHidden } from "./query-util";
export class SlotObserver {
    // TODO: callback return void | Promise<void>
    constructor(componentElement, tagNameToFilter, callback, pierceShadowDom = false) {
        this.componentElement = componentElement;
        this.tagNameToFilter = tagNameToFilter;
        this.callback = callback;
        this.pierceShadowDom = pierceShadowDom;
        this.lastEmittedNodesOuterHtml = null;
        const allNodes = this.findAllNodes();
        if (this.checkAllHydrated(allNodes)) {
            const nodes = this.findAllNodes();
            this.lastEmittedNodesOuterHtml = this.getOuterHTML(nodes);
            callback(nodes);
        }
        this.mutationObserver = new MutationObserver(() => {
            this.onMutation();
        });
        this.connect();
    }
    connect() {
        this.mutationObserver.observe(this.componentElement, {
            childList: true,
            subtree: true,
            attributes: true,
        });
    }
    disconnect() {
        this.mutationObserver.disconnect();
    }
    checkAllHydrated(allNodes) {
        return allNodes.every((node) => {
            if (isDesignSystemComponentTag(node === null || node === void 0 ? void 0 : node.tagName)) {
                return node.classList.contains("hydrated");
            }
            return true;
        });
    }
    findAllNodes() {
        const lowerCaseTagName = this.tagNameToFilter.toLowerCase();
        const selector = isDesignSystemComponentTag(lowerCaseTagName)
            ? `${this.tagNameToFilter}`
            : lowerCaseTagName;
        if (this.pierceShadowDom) {
            return findElementByTagNamePiercingShadowDom(this.componentElement, lowerCaseTagName);
        }
        return Array.from(this.componentElement.querySelectorAll(selector));
    }
    getOuterHTML(nodes) {
        return nodes.map((node) => node.outerHTML).reduceRight((prev, curr) => prev + curr, "");
    }
    onMutation() {
        const nodes = this.findAllNodes();
        if (this.checkAllHydrated(nodes)) {
            const outerHtml = this.getOuterHTML(nodes);
            if (this.lastEmittedNodesOuterHtml !== outerHtml) {
                // don't notify the same content twice
                this.lastEmittedNodesOuterHtml = outerHtml;
                this.callback(nodes);
            }
        }
        else {
            if (this.pierceShadowDom) {
                // Since not all are hydrated and we don't get notified if hydration happens in cascaded shadow DOM
                // we need to recheck some time later.
                setTimeout(() => this.onMutation(), 30);
            }
        }
    }
}
function isDesignSystemComponentTag(tag) {
    const tagName = tag === null || tag === void 0 ? void 0 : tag.toLowerCase();
    return tagName === null || tagName === void 0 ? void 0 : tagName.startsWith("dx-");
}
export const findElementByTagNamePiercingShadowDom = (base, tagName) => {
    const elements = resolveShadowDomAndSlots(base);
    const lowerCaseTagName = tagName.toLowerCase();
    return elements.filter((el) => tagName === "*" || el.tagName.toLowerCase() === lowerCaseTagName);
};
const resolveShadowDomAndSlots = (base) => {
    const result = [];
    // Add all elements
    base.querySelectorAll("*").forEach((el) => result.push(el));
    // Resolve slots
    base.querySelectorAll("slot").forEach((slot) => {
        slot.assignedElements().forEach((slottedEl) => result.push(slottedEl));
    });
    // ShadowRoot scan found elements
    result.forEach((el) => {
        resolveShadowDomAndSlots(el).forEach((shadowedEl) => result.push(shadowedEl));
    });
    return result;
};
/**
 * Create a new SlotObserver with a hidden element constraint. This is mainly called when using a SlotObserver to gather meta-components (e.g. dx-dropdown-option)
 *
 * @param {HTMLElement} componentElement - The HTML element for which the SlotObserver is being created.
 * @param {string} tagNameToFilter - The tag name to be filtered.
 * @param {(nodes: T[]) => void} callback - The callback function that is called when the SlotObserver detects changes.
 * @param {boolean} pierceShadowDom - Optional parameter that determines whether the Shadow DOM should be pierced. By default, this is set to false.
 * @returns {SlotObserver<T>} - Returns a new SlotObserver.
 */
export function createMetaComponentsSlotObserver(componentElement, tagNameToFilter, callback, pierceShadowDom = false) {
    return new SlotObserver(componentElement, notHidden(tagNameToFilter), callback, pierceShadowDom);
}
export function directChildSelector(...element) {
    return element.map((el) => `:scope > ${el}`).join(", ");
}
